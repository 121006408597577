<template>
  <div v-if="show" class="bg">
    <div class="preview-top-container">
      <span>页面：</span>
      <el-select
        v-model="currentPage"
        class="select"
        placeholder="请选择"
        size="medium"
        ref="pageSelector"
        @change="pageChange"
      >
        <el-option
          v-for="item in pageOptions"
          :key="item.value + ''"
          :label="item.label"
          :value="item.value + ''"
          class="pageItem"
        >
          <span>
            {{ item.label }}
          </span>
        </el-option>
      </el-select>
      <el-button class="close" @click="close">关闭</el-button>
    </div>
    <div class="canvas-container">
      <div
        class="canvas"
        ref="canvas"
        :style="{
          width: changeStyleWithScale(getCanvasStyleData.width) + 'px',
          height: changeStyleWithScale(getCanvasStyleData.height) + 'px',
          backgroundImage: `url(${getCanvasStyleData.backPicSrc}) `,
          backgroundSize: `100% 100%`,
          backgroundRepeat: 'no-repeat',
          transformOrigin: '0px 0px'
        }"
      >
        <ComponentWrapper
          v-for="item in useableComponentsData"
          :key="item.id"
          :config="item"
          :allBtnPressMap="allBtnPressMap"
          :currentComps="currentComponent"
          @changeCurComp="changeCurComp"
          @changePage="changePage"
          @changeBtnPress="changeBtnPress"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getStyle } from "@/utils/style";
import { getPicSrc } from "@/utils/utils";
import { mapState } from "vuex";
import ComponentWrapper from "./ComponentWrapper";
import { changeStyleWithScale } from "@/utils/translate";

export default {
  components: { ComponentWrapper },
  model: {
    prop: "show",
    event: "change",
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: '',
      currentComponent: JSON.stringify([]),
      // 由于预览页面中按钮按压联动需要跨页面影响，需要全局记录按钮的按压状态
      allBtnPressMap: {}
    };
  },
  watch: {
    show: function(val, oldVal) {
      if (val && val !== oldVal) {
        // 页面刚展示时，设置下拉选择为当前页面
        this.currentPage = this.selectedCurrentPage + '';
        this.currentComponent = JSON.stringify(this.componentData);
        this.$nextTick(() => {
          this.setEditorReSize()
        })
      }
    }
  },
  computed: {
    ...mapState(["componentData", "canvasStyleData", "projectStyleData", "pageOptions", "selectedCurrentPage"]),
    getCanvasStyleData() {
      const { backPicSrc } = JSON.parse(this.currentComponent)[0]?.style;
      const { width, height } = this.projectStyleData;
      return {
        width,
        height,
        backPicSrc: getPicSrc(backPicSrc),
      };
    },
    useableComponentsData() {
      return JSON.parse(this.currentComponent).filter((c) => c.id != "Back");
    },
  },
  methods: {
    setEditorReSize() {
      if (!this.$refs.canvas) {
        return;
      }
      const parentElem = this.$refs.canvas.parentElement;
      const parentW = parentElem.clientWidth;
      const parentH = parentElem.clientHeight;
      const currentActualW = this.$refs.canvas.clientWidth;
      const currentActualH = this.$refs.canvas.clientHeight;
      const wPercent = parseFloat((parentW / currentActualW).toFixed(2));
      const hPercent = parseFloat((parentH / currentActualH).toFixed(2));
      let finalPer;
      // 取小一点的比例，来保证完全展示在页面中
      if (wPercent < hPercent) {
        finalPer = wPercent;
      } else {
        finalPer = hPercent;
      }
      this.$refs.canvas.setAttribute('data-scale', finalPer);
      this.$refs.canvas.style.transform = `scale(${finalPer}, ${finalPer}) translate(-50%, -50%)`;
    },
    changeStyleWithScale,

    getStyle,
    changeCurComp(datas) {
      this.currentComponent = JSON.stringify(datas);
    },
    changePage(key) {
      this.currentPage = key + '';
      this.pageChange(key);
    },
    changeBtnPress(arr) {
      console.log(arr);
      arr.map((item) => {
        this.$set(this.allBtnPressMap, item.id, item.isPress)
      });
      // this.allBtnPressMap[id] = true;
      // if (!this.allBtnPressMap[id]) {
      //   // 没有初始值
      //   this.allBtnPressMap[id] = true;
      // }
    },
    pageChange(val) {
      const canvasData = JSON.parse(sessionStorage.getItem("canvasData"));
      this.currentComponent = JSON.stringify(canvasData[val]);
    },
    close() {
      this.allBtnPressMap = {};
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgb(0, 0, 0, 0.5);
  z-index: 10;
  // display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 40px;
  .preview-top-container {
    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 10px;
    .close {
      float: right;
    }
  }
  .canvas-container {
    // width: calc(100% - 40px);
    height: calc(100% - 120px);
    overflow: auto;
    background: #fff;
    position: relative;
    .canvas {
      background: #fff;
      position: relative;
      margin: auto;
      border: 1px solid #e5e5e5;
      // border-top: none;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  // .close {
  //   position: absolute;
  //   right: 20px;
  //   top: 20px;
  // }
}
</style>
<template>
  <div class="uploadAreaWrap">
    <div class="uploadArea" @click="handler" v-if="!getPicSrc">
      <i class="el-icon-plus"></i>
    </div>
    <div v-else class="imageWrap" @click="handler">
      <img :src="getPicSrc" class="image" />
      <i class="el-icon-close" @click.stop="deleteHandler" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    handler: {
      type: Function,
      default: () => {},
    },
    deleteHandler: {
      type: Function,
      default: () => {},
    },
    src: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    getPicSrc() {
      if (!this.src) return;
      if (this.src.indexOf('(') !== -1 && this.src.indexOf(')') !== -1) {
        return this.src.split("(")[1]?.split(")")[0];
      } else {
        return this.src;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.uploadAreaWrap {
  .uploadArea {
    font-size: 28px;
    color: #8c939d;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    margin: 10px 0;
  }
  .imageWrap {
    position: relative;
    width: 200px;
    height: 120px;
    .image {
      width: 170px;
      height: 116px;
    }
    .el-icon-close {
      position: absolute;
      top: 1px;
      right: 30px;
      color: red;
      font-size: 26px;
      cursor: pointer;
    }
  }
}
</style>
<template>
    <div class="interaction-btn-container">
        <div class="interaction-btn-left">
            <el-table
                ref="leftTable"
                :data="leftTableDatas"
                border
                style="width: 100%"
                @selection-change="handleLeftCheckedChange"
            >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                    v-for="column in leftTableColumn"
                    :key="column.key"
                    :prop="column.key"
                    :label="column.label"
                >
                </el-table-column>
            </el-table>
        </div>
        <div class="interaction-btn-switches">
            <el-button
            type="primary"
            icon="el-icon-d-arrow-right"
            @click="addClick"
            />
            <el-button
            type="primary"
            icon="el-icon-d-arrow-left"
            @click="removeClick"
            />
        </div>
        <div class="interaction-btn-right">
            <el-table
                :data="rightTableDatas"
                border
                style="width: 100%"
                @selection-change="handleRightCheckedChange"
            >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                    v-for="column in rightTableColumn"
                    :key="column.key"
                    :prop="column.key"
                    :label="column.label"
                    :width="column.key === 'events' ? '80px' : undefined"
                >
                    <template slot-scope="scope">
                        <el-select
                            v-if="column.key === 'status'"
                            size="mini"
                            v-model="scope.row.status"
                            placeholder="请选择"
                            :disabled="scope.row.events === '1'"
                        >
                            <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-else-if="column.key === 'events'" size="mini" v-model="scope.row.events" placeholder="请选择">
                            <el-option
                                v-for="item in eventsOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span v-else>{{ scope.row[column.key] }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        initDatas: Array
    },
    data() {
        return {
            leftTableDatas: [],
            leftCheckDatas: [],
            leftTableColumn: [
                { label: '页面名称', key: 'pageName' },
                { label: '按钮名称', key: 'buttonName' },
                { label: '按钮别名', key: 'alias' }
            ],
            rightTableDatas: [],
            rightCheckDatas: [],
            rightTableColumn: [
                { label: '页面名称', key: 'pageName' },
                { label: '按钮名称', key: 'buttonName' },
                { label: '按钮别名', key: 'alias' },
                { label: '触发事件', key: 'events' },
                { label: '按压效果', key: 'status' }
            ],
            statusOptions: [
                { value: "0", label: "初始状态" },
                { value: "1", label: "按压状态" }
            ],
            eventsOptions: [
                { value: "0", label: "否" },
                { value: "1", label: "是" }
            ]
        };
    },
    watch: {
        initDatas: {
            handler: function(val) {
                var arr = JSON.parse(JSON.stringify(val || []));
                // 兼容历史数据没有“触发事件”
                arr.forEach((item, index) => {
                    if (!item.events) {
                        arr[index].events = '0';
                    }
                });
                this.rightTableDatas = arr;
                this.rightCheckDatas = [];
                this.leftCheckDatas = [];
            },
            immediate: true
        }
    },
    created() {
        this.getLeftDatas();
    },
    methods: {
        getDatas() {
            return JSON.parse(JSON.stringify(this.rightTableDatas));
        },
        getLeftDatas() {
            let canvasData = sessionStorage.getItem("canvasData");
            canvasData = canvasData ? JSON.parse(canvasData) : {};
            let arr = [];
            Object.keys(canvasData).forEach((pageId) => {
                let comps = canvasData[pageId];
                let pageInfo = {
                    pageId: pageId,
                    pageName: ''
                };
                comps.forEach((item) => {
                    if (item.component === 'Back') {
                        pageInfo.pageName = item.style.pageName || '';
                    } else if (item.component === 'v-button') {
                        // 按钮数据
                        arr.push({
                            pageId: pageId,
                            pageName: pageInfo.pageName,
                            id: item.id,
                            alias: item.style.alias,
                            buttonName: item.propValue,
                            status: '0',
                            events: '0'
                        });
                    }
                });
            });
            this.leftTableDatas = arr;
        },
        addClick() {
            if (this.leftCheckDatas.length === 0) {
                this.$message.error('请勾选左侧要添加的数据');
                return;
            }
            // 去除右侧已有的数据，再去添加
            let datas = this.leftCheckDatas.filter(item => {
                let ind = this.rightTableDatas.findIndex(a => a.id === item.id);
                return ind === -1;
            });
            if (datas && datas.length !== 0) {
                this.rightTableDatas = [ ...this.rightTableDatas, ...datas ];
            }
            this.leftCheckDatas = [];
            this.$refs.leftTable.clearSelection();
        },
        removeClick() {
            if (this.rightCheckDatas.length === 0) {
                this.$message.error('请勾选右侧要移除的数据');
                return;
            }
            this.rightCheckDatas.forEach((item) => {
                let ind = this.rightTableDatas.findIndex(a => a.id === item.id);
                this.rightTableDatas.splice(ind, 1);
            });
            this.rightCheckDatas = [];
        },
        handleLeftCheckedChange(selection) {
            this.leftCheckDatas = selection;
        },
        handleRightCheckedChange(selection) {
            this.rightCheckDatas = selection;
        }
    }
};
</script>
<style lang="scss">
.interaction-btn-switches {
    .el-button {
        margin-left: 0px;
        margin-top: 10px;
    }
}
</style>
<style lang="scss" scoped>
.interaction-btn-container {
    display: flex;
    margin-top: 10px;
    height: calc(100% - 42px);
    .interaction-btn-left {
        flex-grow: 1;
        width: 37%;
    }
    .interaction-btn-switches {
        width: 90px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .interaction-btn-right {
        flex-grow: 1;
    }
}
</style>
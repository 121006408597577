<template>
    <el-drawer
        title="交互包"
        :visible.sync="drawerShow"
        direction="rtl"
        size="90%"
        :wrapperClosable="false"
        :before-close="handleClose"
        :modal-append-to-body="true"
        :append-to-body="true"
        :destroy-on-close="true"
        class="jhb-drawer"
    >
        <div class="jhb-container" v-if="drawerShow">
            <div class="jhb-left">
                <el-tree
                    ref="leftTree"
                    node-key="value"
                    :highlight-current="true"
                    :default-expand-all="true"
                    :expand-on-click-node="false"
                    current-node-key="0"
                    :data="leftData"
                    :props="leftProps"
                    @node-click="handleNodeClick"
                >
                    <span class="jhb-tree-node" slot-scope="{ node }">
                        <span :title="node.label">{{ node.label }}</span>
                    </span>
                </el-tree>
            </div>
            <div class="jhb-right">
                <div v-if="leftSelectVal === '0'">
                    <div class="jhb-all-tool">
                        <el-button size="small" type="primary" @click="addAllClick">
                            添加
                        </el-button>
                    </div>
                    <el-table
                        :data="allDatas"
                        :border="true"
                        style="width: 100%"
                    >
                        <el-table-column
                            v-for="column in allTableColumns"
                            :key="column.value"
                            :prop="column.value"
                            :label="column.label"
                        >
                            <template slot-scope="scope">
                                <span v-if="column.value === 'enter_space'">{{ enter_space_text(scope.row[column.value]) }}</span>
                                <span v-else-if="column.value === 'unicode'">{{ unicode_text(scope.row[column.value]) }}</span>
                                <div v-else-if="column.value === 'oper'">
                                    <el-button size="small" type="text" @click="() => {editXiyiClick(scope.row)}">
                                        编辑
                                    </el-button>
                                    <el-button size="small" type="text" @click="() => {deleteXiyiClick(scope.$index)}">
                                        删除
                                    </el-button>
                                </div>
                                <span v-else>{{ scope.row[column.value] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-else style="height: 100%;">
                    <div>
                        <el-radio-group v-model="rightTopType" @input="topTypeChange">
                            <el-radio-button label="zljset">指令集设置</el-radio-button>
                            <el-radio-button label="btnset">按钮状态设置</el-radio-button>
                        </el-radio-group>
                    </div>
                    <DirectivesComp v-if="actualRightTopType === 'zljset'" :isJhb="true" ref="directivesCompRef" :initData="directivesDatas"></DirectivesComp>
                    <InteractionBtnComp v-else ref="interactionBtnRef" :initDatas="btnsDatas"></InteractionBtnComp>
                </div>
            </div>
        </div>
        <el-dialog
            title="添加协议"
            :visible="xiyiModalShow"
            :destroy-on-close="true"
            width="700px"
            :before-close="handleXiyiModalClose"
            :modal-append-to-body="true"
            :append-to-body="true"
        >
            <el-form
                :model="xiyiData"
                ref="xiyiForm"
                label-width="100px"
                :rules="xiyiformRules"
            >
                <el-form-item label="协议类型" prop="send_type">
                    <el-select
                        v-model="xiyiData.send_type"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in send_type"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <p v-if="!!xiyiData.send_type" class="send-type-tip">{{ send_type_tip_map[xiyiData.send_type] }}</p>
                </el-form-item>
                <el-form-item label="IP地址" prop="ipAdress">
                    <el-input
                        v-model="xiyiData.ipAdress"
                        placeholder="请输入IP地址"
                    ></el-input>
                </el-form-item>
                <el-form-item label="冻结时间" prop="freezeTime">
                    <el-input-number
                        v-model="xiyiData.freezeTime"
                        :min="0"
                        :controls="false"
                        :precision="0"
                        placeholder="请输入冻结时间"
                        class="freeze-time-input"
                    ></el-input-number>
                    <span style="margin-left: 5px;">秒</span>
                </el-form-item>
                <el-form-item v-if="['udp', 'tcpip'].includes(xiyiData.send_type)" label="消息进制" prop="unicode">
                    <el-radio-group v-model="xiyiData.unicode">
                        <el-radio
                            v-for="item in unicodeOptions"
                            :key="item.value"
                            :label="item.value"
                        >{{item.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="xiyiData.send_type === 'http'">
                    <el-form-item label="请求方式" prop="req_modes">
                        <el-select v-model="xiyiData.req_modes" placeholder="请选择请求方式" @change="reqModesChange">
                            <el-option v-for="item in req_modes_options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="请求体类型" prop="param_type" v-show="false">
                        <el-radio-group v-model="xiyiData.param_type" @change="paramTypeChange">
                            <el-radio label="1" name="param_type">键值对</el-radio>
                            <el-radio label="0" name="param_type">json格式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="xiyiData.param_type === '1'" label="请求体"
                        prop="req_body">
                        <div class="table-div">
                            <el-table :data="xiyiData.req_body" border style="width: 100%">
                            <el-table-column v-for="cell in editableCells" :key="cell.key" :prop="cell.key" :min-width="cell.width"
                                :label="cell.label">
                                <template slot-scope="scope">
                                <el-button v-if="cell.key === 'oper'" @click="removeDatas('req_body', scope.$index)">删除</el-button>
                                <el-input v-else :value="scope.row[cell.key]" :placeholder="cell.placeholder" @input="
                                    (v) => {
                                    onTableCellChange(v, scope.row, cell.key, 'req_body');
                                    }
                                "></el-input>
                                </template>
                            </el-table-column>
                            </el-table>
                            <el-button type="primary" @click="addDatas('req_body')" class="table-add-btn">添加</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="xiyiData.param_type === '0'" label="请求体"
                        prop="req_body_json">
                        <el-input type="textarea" placeholder="json格式" v-model="xiyiData.req_body_json" @change="reqBodyJsonChange"></el-input>
                    </el-form-item>
                </template>
                <el-form-item :label="xiyiData.send_type ==='http' ? '预览' : '消息'" prop="msg">
                    <el-input
                        v-model="xiyiData.msg"
                        :placeholder="xiyiData.send_type ==='http' ? '' : '请输入消息'"
                        :disabled="xiyiData.send_type === 'http'"
                    ></el-input>
                </el-form-item>
                <el-form-item v-if="['udp', 'tcpip'].includes(xiyiData.send_type) && xiyiData.unicode === '1'" label="回车/空格" prop="enter_space">
                    <el-radio-group v-model="xiyiData.enter_space">
                        <el-radio
                            v-for="item in enter_space"
                            :key="item.value"
                            :label="item.value"
                        >{{item.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleXiyiModalClose">取 消</el-button>
                <el-button type="primary" @click="xiyiModalConfirm">确 定</el-button>
            </span>
        </el-dialog>
        <div class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { uuid, validateIp } from '../utils/utils';
import DirectivesComp from './DirectivesComp/index';
import InteractionBtnComp from './InteractionBtn/index';

export default {
    data() {
        var validateIpAdress = (rule, value, callback) => {
            if (value && value.trim() !== '') {
                let isRight = validateIp(value);
                if (!isRight) {
                    callback('请输入正确的IP地址');
                    return;
                }
            }
            callback();
        };
        return {
            drawerShow: false,
            leftProps: {
                children: 'children',
                label: 'label'
            },
            leftSelectVal: '0',
            allDatas: [],
            allTableColumns: [
                { label: '协议类型', value: 'send_type' },
                { label: '消息进制', value: 'unicode' },
                { label: '消息', value: 'msg' },
                { label: '回车/空格', value: 'enter_space' },
                { label: '操作', value: 'oper' }
            ],
            xiyiModalShow: false,
            xiyiData: this.initXiyiForm(),
            send_type: [],
            enter_space: [
                { label: '否', value: '0' },
                { label: '回车', value: '1' },
                { label: '空格', value: '2' },
                { label: '回车/换行', value: '3' },
                { label: '换行/回车', value: '4' }
            ],
            unicodeOptions: [
                { label: '字符串', value: '1' },
                { label: '16进制', value: '2' }
            ],
            req_modes_options: [
                { label: 'GET', value: '0' },
                { label: 'POST', value: '1' }
            ],
            editableCells: [
                {
                    label: "参数名",
                    key: "name",
                    placeholder: "请输入",
                    width: "3",
                },
                {
                    label: "参数值",
                    key: "value",
                    placeholder: "请输入",
                    width: "2",
                },
                {
                    label: "操作",
                    key: "oper",
                    width: "2",
                }
            ],
            xiyiformRules: {
                send_type: [
                    { required: true, message: '请选择协议类型', trigger: "change" },
                ],
                ipAdress: [
                    { validator: validateIpAdress, trigger: 'blur' }
                ],
                freezeTime: [
                    { required: true, message: '请输入冻结时间', trigger: "blur" }
                ],
                unicode: [
                    { required: true, message: '请选择消息进制', trigger: "change" }
                ],
                msg: [
                    { required: true, message: '请输入消息', trigger: "blur" },
                ],
                enter_space: [
                    { required: true, message: '请选择回车/空格', trigger: "change" },
                ],
                req_modes: [
                    { required: true, message: "请选择请求方式", trigger: "change" },
                ],
                param_type: [
                    { required: true, message: "请选择请求体类型", trigger: "change" },
                ],
                req_body_json: [
                    { required: true, message: "请添加请求体", trigger: "change" },
                ],
                req_body: [
                    { required: true, message: "请添加请求体", trigger: "change" },
                ]
            },
            rightTopType: 'zljset',
            btnTableDatas: [],
            btnTableColumn: [
                { label: '页面名称', key: 'pageName' },
                { label: '按钮名称', key: 'inputContent' },
                { label: '按钮别名', key: 'alias' },
                { label: '按压效果', key: 'btnPressType' },
                { label: '操作', key: 'oper' }
            ],
            actualRightTopType: 'zljset',
            send_type_tip_map: {
                'udp': '使用设备IP地址（关于本机中查看）+端口1088，进行请求控制，注意数据格式类型',
                'tcpip': '使用设备IP地址（关于本机中查看）+端口1077，进行请求控制，注意数据格式类型',
                'http': '使用设备IP地址（关于本机中查看）+端口1099，进行请求控制，例如：\n选择GET 请求时 例：http://192.168.3.6:1099/smart_control?command=light\n选择POST请求时 例：http://192.168.3.6:1099/smart_control'
            }
        };
    },
    components: {
        DirectivesComp,
        InteractionBtnComp
    },
    computed: {
        directivesDatas() {
            let dataInd = this.allDatas.findIndex(item => item.id == this.leftSelectVal);
            let directives = this.allDatas[dataInd].directives || [];
            return directives;
        },
        btnsDatas() {
            let dataInd = this.allDatas.findIndex(item => item.id == this.leftSelectVal);
            let btns = this.allDatas[dataInd].btns || [];
            return btns;
        },
        leftData() {
            var initData = [
                { label: '全部', value: '0' }
            ];
            this.allDatas.forEach((item) => {
                if (!initData[0].children) {
                    initData[0].children = [];
                }
                initData[0].children.push({
                    label: item.send_type + '-' + item.msg,
                    value: item.id
                });
            });
            return initData;
        },
        enter_space_text() {
            return (val) => {
                let item = this.enter_space.filter(a => a.value === val);
                return item && item[0] && item[0].label || '';
            };
        },
        unicode_text() {
            return (val) => {
                let item = this.unicodeOptions.filter(a => a.value === val);
                return item && item[0] && item[0].label || '';
            };
        }
    },
    watch: {
        drawerShow: function(val) {
            if (val) {
                this.getSendTypes();
            }
        }
    },
    methods: {
        addDatas(key) {
            this.xiyiData[key].push({
                name: '',
                value: '',
                id: uuid()
            });
        },
        removeDatas(formKey, i) {
            this.xiyiData[formKey].splice(i, 1);
            this.setHttpMsg();
        },
        onTableCellChange(v, row, valueKey, formKey) {
            const i = this.xiyiData[formKey].findIndex(
                (i) => i.id == row.id
            );
            this.xiyiData[formKey][i][valueKey] = v;
            // 请求体改变了，需要改变消息的值
            this.setHttpMsg();
        },
        reqModesChange() {
            if (this.xiyiData.req_modes === '0') {
                // get请求
                this.xiyiData.param_type = '1';
            } else {
                // post请求
                this.xiyiData.param_type = '0';
            }
            this.setHttpMsg();
        },
        paramTypeChange() {
            this.setHttpMsg();
        },
        reqBodyJsonChange() {
            this.setHttpMsg();
        },
        setHttpMsg() {
            let req_body = '';
            if (this.xiyiData.param_type === '1') {
                if (this.xiyiData.req_body.length !== 0) {
                    req_body = this.xiyiData.req_body.reduce((obj, item) => {
                        if (item.name && item.value) {
                            obj.push(`${item.name}=${item.value}`);
                        }
                        return obj;
                    }, []);
                    if (req_body.length !== 0) {
                        req_body = '?' + req_body.join('&');
                    } else {
                        req_body = '';
                    }
                }
            } else {
                req_body = this.xiyiData.req_body_json;
            }
            this.xiyiData.msg = req_body;
        },
        topTypeChange(val) {
            if (this.actualRightTopType === 'zljset' && this.$refs.directivesCompRef) {
                // 校验是否正确，再去切换类型
                let ret = this.$refs.directivesCompRef.getDirectivesDatas();
                if (!ret) {
                    // 有不正确的数据，切回之前的节点
                    this.$nextTick(() => {
                        this.rightTopType = 'zljset';
                    });
                    return;
                }
                this.updateDirectives(ret, 'directives');
            } else {
                let ret = this.$refs.interactionBtnRef.getDatas();
                this.updateDirectives(ret, 'btns');
            }
            this.actualRightTopType = val;
        },
        getSendTypes() {
            this.$changeLoadingState(true);
            this.$request.getTypeOptions({ type: "send_type" }).then((res) => {
                this.$changeLoadingState(false);
                if (res.status != "success") {
                    this.$message.error(res.msg);
                    return;
                }
                let datas = [];
                if (res && res.data && res.data.length !== 0) {
                    res.data.map((item) => {
                        datas.push({
                            label: item.lable,
                            value: item.value
                        });
                    });
                }
                this.send_type = datas;
            }).catch(() => {
                this.$changeLoadingState(false);
            });
        },
        initXiyiForm() {
            return {
                send_type: '',
                ipAdress: '',
                freezeTime: '',
                msg: '',
                enter_space: '0',
                unicode: '1',
                req_modes: "",
                param_type: '1',
                req_body_json: '',
                req_body: []
            };
        },
        show() {
            this.drawerShow = true;
            // 初始化数据回显
            let initData = sessionStorage.getItem("interactionPackageData");
            this.allDatas = initData ? JSON.parse(initData) : [];
        },
        handleClose() {
            this.drawerShow = false;
            this.leftSelectVal = '0';
            this.allDatas = [];
        },
        handleNodeClick(data) {
            if (this.$refs.directivesCompRef) {
                // 当前有指令集，去获取数据判断下有没有不符合的数据
                let ret = this.$refs.directivesCompRef.getDirectivesDatas();
                if (!ret) {
                    // 有不正确的数据，切回之前的节点
                    this.$refs.leftTree.setCurrentKey(this.leftSelectVal);
                    return;
                }
                this.updateDirectives(ret, 'directives');
            } else if (this.$refs.interactionBtnRef) {
                let ret = this.$refs.interactionBtnRef.getDatas();
                this.updateDirectives(ret, 'btns');
            }
            this.leftSelectVal = data.value;
        },
        updateDirectives(ret, propKey) {
            let dataInd = this.allDatas.findIndex(item => item.id == this.leftSelectVal);
            this.$set(this.allDatas, dataInd, { ...this.allDatas[dataInd], [propKey]: ret });
            // 因为值变更了，会导致树重新渲染，所以需要重新选中节点
            this.$nextTick(() => {
                this.$refs.leftTree.setCurrentKey(this.leftSelectVal);
            });
        },
        addAllClick() {
            this.xiyiModalShow = true;
        },
        editXiyiClick(row) {
            this.xiyiData = Object.assign({}, row);
            this.xiyiModalShow = true;
        },
        deleteXiyiClick(ind) {
            this.$confirm('确定删除该条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var id = this.allDatas[ind].id;
                if (this.leftSelectVal === id) {
                    this.leftSelectVal = '0';
                }
                this.allDatas.splice(ind, 1);
            }).catch(() => {
                // 取消
            });
        },
        handleXiyiModalClose() {
            this.xiyiModalShow = false;
            this.xiyiData = this.initXiyiForm();
        },
        xiyiModalConfirm() {
            this.$refs.xiyiForm.validate((valid) => {
                if (valid) {
                    // 验证通过
                    // 验证请求头和请求体
                    if (this.xiyiData.send_type === 'http') {
                        if (this.xiyiData.param_type === '0') {
                            var isNumber = !isNaN(Number(this.xiyiData.req_body_json));
                            if (!isNumber) {
                                try {
                                    JSON.parse(this.xiyiData.req_body_json);
                                } catch (error) {
                                    this.$message.error('请求体请填写正确的json格式');
                                    return;
                                }
                            }
                        } else {
                            let isBodyEmpty = false;
                            for (let k = 0; k < this.xiyiData.req_body.length; k++) {
                                if (!this.xiyiData.req_body[k].name || !this.xiyiData.req_body[k].value) {
                                    isBodyEmpty = true;
                                }
                            }
                            if (isBodyEmpty) {
                                this.$message.error('请求体中存在空的值，请检查并补全');
                                return;
                            }
                        }
                    }
                    let data = JSON.parse(JSON.stringify(this.xiyiData));
                    if (data.send_type === 'http' || (data.send_type !== 'http' && data.unicode !== '1')) {
                        data.enter_space = '';
                    }
                    if (this.xiyiData.id) {
                        // 编辑
                        let ind = this.allDatas.findIndex(a => a.id === this.xiyiData.id);
                        this.$set(this.allDatas, ind, Object.assign({}, data));
                    } else {
                        this.allDatas.push(Object.assign({}, data, {
                            id: uuid()
                        }));
                    }
                    this.handleXiyiModalClose();
                }
            });
        },
        confirm() {
            this.$confirm('确定要将本次交互包的改变更新吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 需要将当前选中节点的数据更新下，再去保存
                if (this.$refs.directivesCompRef) {
                    // 当前有指令集，去获取数据判断下有没有不符合的数据
                    let ret = this.$refs.directivesCompRef.getDirectivesDatas();
                    if (!ret) {
                        // 有不正确的数据，切回之前的节点
                        this.$refs.leftTree.setCurrentKey(this.leftSelectVal);
                        return;
                    }
                    this.updateDirectives(ret, 'directives');
                } else if (this.$refs.interactionBtnRef) {
                    let ret = this.$refs.interactionBtnRef.getDatas();
                    this.updateDirectives(ret, 'btns');
                }
                // 执行保存，并关闭
                sessionStorage.setItem("interactionPackageData", JSON.stringify(this.allDatas)); 
                this.handleClose();
            });
        }
    }
};
</script>
<style lang="scss">
.jhb-drawer {
    .el-drawer__header {
        margin-bottom: 20px;
    }
    .jhb-left {
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
            background: #bdd9f9;
        }
        .el-tree-node__content {
            height: 40px;
        }
    }
}
.freeze-time-input.el-input-number {
    .el-input__inner {
        text-align: left;
    }
}
</style>
<style lang="scss" scoped>
.send-type-tip {
    margin-top: 10px;
    color: red;
    white-space: break-spaces;
}
.jhb-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 72px);
    .jhb-left {
        width: 200px;
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        border-right: 1px solid #ccc;
        .jhb-tree-node {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .jhb-right {
        flex-grow: 1;
        padding: 0px 20px;
        max-width: calc(100% - 240px);
        height: 100%;
        overflow: auto;
        .jhb-all-tool {
            margin-bottom: 20px;
        }
    }
}
.dialog-footer {
    width: 100%;
    text-align: right;
    background: #fff;
    padding: 20px 10px;
}
</style>

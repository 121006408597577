<script>
import { mapGetters, mapActions } from "vuex";
import Pagination from "@/components/Pagination";

export default {
  props: ['isDialog', 'selectedM'],
  data() {
    return {
      activeName: "",
      pageInfo: {
        page_num: 1,
        page_size: this.isDialog ? 8 : 18,
      },
      oldParams: {},
    };
  },
  computed: {
    ...mapGetters([
      "materialTypeList",
      "materialTableDatas",
      "materialTableListTotal",
    ]),
  },
  watch: {
    materialTypeList: {
      handler: function (val) {
        if (val && val.length > 0) {
          this.activeName = val[0].value;
          this.getList(null, true);
        }
      },
      deep: true,
      immediate: true
    },
		activeName: function(val, oldVal) {
			if (val !== oldVal) {
				this.getList(null, true);
			}
		}
  },
  methods: {
    ...mapActions([
      "getMaterialTableList"
    ]),
    getTableData(params) {
      this.getList(params);
    },
    async getList(params, isFirstPage) {
      if (isFirstPage) {
        this.pageInfo.page_num = 1;
      }
      let queryParams = {
        ...this.oldParams,
				file_type: this.activeName,
        ...this.pageInfo,
      };
      if (params) {
        Object.keys(this.pageInfo).forEach((key) => {
          if (params.hasOwnProperty(key)) {
            this.pageInfo[key] = params[key];
          }
        });
        queryParams = {
          ...queryParams,
          ...params,
        };
      }
      try {
        this.$changeLoadingState(true);
        await this.getMaterialTableList(queryParams);
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
      this.oldParams = queryParams;
    },
    imgClick(item) {
      if (this.selectedM.id === item.id) {
        return;
      }
      this.$emit('selected', item);
    }
  },
  components: {
    Pagination,
  },
};
</script>
<template>
  <div class="ordinary-pic-container" :style="{padding: isDialog ? '0px' : 'auto'}">
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="item in materialTypeList"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
        <el-empty v-if="!materialTableDatas || materialTableDatas.length === 0" :image-size="100"></el-empty>
				<template v-else>
          <div class="ordinary-pic-row" :style="{height: isDialog ? 'calc(100% - 60px)' : 'calc(100% - 80px)' }">
					<el-row :gutter="10">
						<el-col
							v-for="item in materialTableDatas"
							:key="item.id"
							:span="6"
              :style="{height: isDialog ? '50%' : '30%'}"
						>
              <div :class="['imgDiv', selectedM.id === item.id ? 'selected': '']" @click="imgClick(item)">
                <el-image :src="item.file_down_path" fit="contain">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <i class="el-icon-success selectedIcon" v-if="selectedM.id == item.id"></i>
              </div>
						</el-col>
					</el-row>
				</div>
        <Pagination
          v-if="materialTableListTotal > 0"
          :handleSizeChange="
            (v) => getTableData({ page_size: v })
          "
          :handleCurrentPageChange="
            (v) => getTableData({ page_num: v })
          "
          :total="materialTableListTotal"
          :pageNum="pageInfo.page_num"
					:pageSizes="isDialog ? [8, 16, 24, 36] : [18, 24, 30, 36]"
        />
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style lang="scss" scoped>
.ordinary-pic-container {
	background: #fff;
	height: 100%;
	padding: 10px;
}
.ordinary-pic-row {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100% - 80px);
}
.imgDiv {
  height: 100%;
  box-sizing: border-box;
  border: 3px solid #f5f5f5;
  border-radius: 5px;
  position: relative;
  &.selected {
    border-color: #fa9d07;
    .selectedIcon {
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fa9d07;
      opacity: 1;
      width: 22px;
      align-items: center;
      justify-content: center;
      height: 22px;
      display: flex;
      font-size: 28px;
      background-color: #fff;
    }
  }
}
</style>
<style lang="scss">
.ordinary-pic-container {
	.el-tabs__nav-wrap {
		text-align: center;
		&::after {
			content: none;
		}
		.el-tabs__nav-scroll {
			display: inline-block;
		}
	}
	.el-tabs__content {
		.el-tab-pane {
			height: 100%;
		}
		.el-row {
			height: 100%;
		}
		.el-col {
			height: 30%;
			margin-top: 10px;
			.el-image {
				height: 100%;
				width: 100%;
				text-align: center;
				background: #f5f7fa;
				.image-slot {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					color: #909399;
				}
			}
		}
	}
}
</style>

<template>
  <el-dialog
    title="更改页面顺序"
    :visible="dialogVisible"
    width="30%"
    :destroy-on-close="true"
    @open="initData"
    @close="dialogVisible = false"
  >
  <div style="overflow: auto; height: 500px;">
    <div class="sortWrap">
      <draggable v-model="sortedArray">
        <div
          v-for="element in sortedArray"
          :key="element.value"
          class="oneOption"
        >
          {{ element.label }}
        </div>
      </draggable>
    </div>
  </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmToChangeOrder">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      sortedArray: [],
    };
  },
  components: {
    draggable,
  },
  computed: mapState(["pageOptions"]),

  methods: {
    initData() {
      this.sortedArray = this.pageOptions;
    },
    switchDialogVisible(bol) {
      this.dialogVisible = bol;
    },
    confirmToChangeOrder() {
      this.$store.commit("setPageOptions", this.sortedArray);
      this.switchDialogVisible(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.sortWrap {
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(230, 230, 230);
  border-image: initial;
  .oneOption {
    height: 10%;
    line-height: 40px;
    cursor: pointer;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
}
</style>
<template>
  <el-dialog
    title="绑定指令"
    :visible="isShowModal"
    width="90%"
    custom-class="dialogContent"
    :destroy-on-close="true"
    @close="dialogClose"
    @open="initDirectivesData"
  >
    <div class="allDirectives allDirectivesLight">
      <el-tabs v-model="activeName" v-if="isShowModal">
        <el-tab-pane label="私有指令" name="my">
          <el-form
            :inline="true"
            size="mini"
            :model="searchDirectivesConditions"
            class="searchForm"
          >
            <el-form-item label="名称">
              <el-input
                v-model="searchDirectivesConditions.directiveName"
                placeholder="请输入指令名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select
                v-model="searchDirectivesConditions.directiveType"
                placeholder="指令类型"
                clearable
              >
                <el-option
                  v-for="d in directiveTypes"
                  :key="d.id"
                  :label="d.type_name"
                  :value="d.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="searchBtn" @click="searchDirectives"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            ref="privateTable"
            :data="directivesDataInfo.result"
            border
            style="width: 100%"
            :height="338"
            class="allDirectivesTable"
            highlight-current-row
            @current-change="handleCurrentChange"
            >
            <el-table-column prop="instruction_name" label="指令名称"></el-table-column>
            <el-table-column prop="type_name" label="类型"></el-table-column>
            <el-table-column prop="instruction_code" label="指令码"></el-table-column>
            <el-table-column prop="return_code" label="指令回码"></el-table-column>
          </el-table>
          <Pagination
            ref="pagination"
            v-if="directivesDataInfo.total > 8"
            layout="total, prev, pager, next"
            :handleSizeChange="handleSizeChange"
            :handleCurrentPageChange="handleCurrentPageChange"
            :total="directivesDataInfo.total"
          />
        </el-tab-pane>
        <el-tab-pane label="公共指令" name="public">
          <el-form
            :inline="true"
            size="mini"
            :model="commonSearchForm"
            class="searchForm"
          >
            <el-form-item label="名称">
              <el-input
                v-model="commonSearchForm.instruction_name"
                placeholder="请输入指令名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-cascader
                v-model="commonSearchForm.level"
                :options="directiveTreeDatas"
                clearable
                :props="{
                  label: 'name',
                  children: 'children',
                  value: 'id',
                  checkStrictly: true,
                }"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="searchBtn" @click="commonSearchClick"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            ref="publicTable"
            :data="directiveTableDatas"
            border
            style="width: 100%"
            :height="338"
            class="allDirectivesTable"
            highlight-current-row
            @current-change="handleCurrentChange"
            >
            <el-table-column prop="instruction_name" label="指令名称"></el-table-column>
            <el-table-column prop="send_type" label="类型"></el-table-column>
            <el-table-column prop="instruction_code" label="指令码"></el-table-column>
            <el-table-column prop="return_code" label="指令回码"></el-table-column>
          </el-table>
          <Pagination
            ref="commonPagination"
            v-if="directiveTableListTotal > 8"
            layout="total, prev, pager, next"
            :handleSizeChange="handleCommonSizeChange"
            :handleCurrentPageChange="handleCommonCurrentPageChange"
            :total="directiveTableListTotal"
            :pageNum="commonPageInfo.page_num"
          />
        </el-tab-pane>
      </el-tabs>

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isShowModal = false">取 消</el-button>
      <el-button type="primary" @click="bindDirectives">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Modal from "@/components/Modal";
import Pagination from "@/components/Pagination";
import BatchDirectivesModal from './BatchDirectivesModal';
import _ from "lodash";

export default {
  components: { Modal, Pagination, BatchDirectivesModal },
  data() {
    return {
      isShowModal: false,
      searchDirectivesConditions: {
        directiveName: "",
        directiveType: "",
      },
      directiveTypes: [],
      directivesDataInfo: {},
      activeName: 'my',
      commonSearchForm: {
        level: [],
        instruction_name: ''
      },
      commonPageInfo: {
        page_num: 1,
        page_size: 6
      },
      currentRow: null
    };
  },
  mounted() {
    this.initDirectivesData();
    this.getDirectiveTypes();
  },
  watch: {
    activeName: function(val, oldVal) {
      if (val !== oldVal) {
        this.clearSelected();
      }
    }
  },
  computed: {
    ...mapState(["curComponent"]),
    ...mapGetters([
      "directiveTreeDatas",
      "directiveTableDatas",
      "directiveTableListTotal"
    ]),
  },
  methods: {
    ...mapActions([
      "getDirectiveTree",
      "getDirectiveTableList"
    ]),
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    initView() {
      this.commonSearchForm = {
        level: [],
        instruction_name: ''
      };
      this.commonPageInfo = {
        page_num: 1,
        page_size: 6
      };
      if (sessionStorage.getItem("modalLevel")) {
        const level = JSON.parse(sessionStorage.getItem("modalLevel"));
        this.commonSearchForm.level = level;
      }
      if (sessionStorage.getItem("modalDirectiveType")) {
        const type = sessionStorage.getItem("modalDirectiveType");
        this.searchDirectivesConditions.directiveType = type;
      }
    },
    async getCommonTreeDatas() {
      try {
        this.$changeLoadingState(true);
        await this.getDirectiveTree();
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
    },
    commonSearchClick() {
      // 记录搜索条件中的--级别
      sessionStorage.setItem(
          "modalLevel",
          JSON.stringify(this.commonSearchForm.level)
        );
      this.getCommonInstList(true);
    },
    async getCommonInstList(isFirstPage) {
      if (isFirstPage) {
        this.commonPageInfo.page_num = 1;
      }
      let queryParams = {
        ...this.commonPageInfo,
        instruction_name: this.commonSearchForm.instruction_name,
        exclude_code: ['http'],
        ...Object.assign({}, 
          this.commonSearchForm.level.length === 2  ? { company_id: this.commonSearchForm.level[1], public_type_id: '' } : {},
          this.commonSearchForm.level.length === 3 ? { company_id: '', public_type_id: this.commonSearchForm.level[2] } : {}
        )
      }
      try {
        this.$changeLoadingState(true);
        await this.getDirectiveTableList(queryParams);
        this.clearSelected();
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
    },
    clearSelected() {
      this.currentRow = null;
      if (this.$refs.privateTable) {
        this.$refs.privateTable.setCurrentRow();
      }
      if (this.$refs.publicTable) {
        this.$refs.publicTable.setCurrentRow();
      }
    },
    dialogClose() {
      this.isShowModal = false;
    },
    switchModalVisible(bol) {
      this.isShowModal = bol;
      if (bol) {
        this.initView();
        this.getCommonTreeDatas();
        this.getCommonInstList(true);
      }
    },
    handleSizeChange(v) {
      this.getDirectives({ page_size: v });
    },
    handleCurrentPageChange(v) {
      this.getDirectives({ page_num: v });
    },
    handleCommonSizeChange(v) {
      this.commonPageInfo.page_size = v;
      this.getCommonInstList(true);
    },
    handleCommonCurrentPageChange(v) {
      this.commonPageInfo.page_num = v;
      this.getCommonInstList();
    },
    getDirectiveTypes() {
      this.$request
        .queryDirectiveTypesList({ page_size: 9999, page_num: 1 })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.directiveTypes = res?.data?.result || [];
        });
    },
    getDirectives({ page_size = 6, page_num = 1 }) {
      const { directiveName, directiveType } =
        this.searchDirectivesConditions || {};
      this.$request
        .queryDirectivesList({
          page_num,
          page_size,
          instruction_name: directiveName,
          type_id: directiveType,
          exclude_code: ['http']
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.directivesDataInfo = res?.data || {};
          this.clearSelected();
        });
    },
    initDirectivesData() {
      this.getDirectives({});
    },
    searchDirectives() {
      const { directiveName, directiveType } = this.searchDirectivesConditions;
      if (this.$refs.pagination) {
        this.$refs.pagination.setPageSize(6);
      }
      // 记录搜索条件中的--类型
      sessionStorage.setItem(
          "modalDirectiveType",
          this.searchDirectivesConditions.directiveType
        );
      this.getDirectives({
        instruction_name: directiveName,
        type_id: directiveType,
      });
    },
    bindDirectives() {
      if (!this.currentRow) {
        this.$message.error('请点击选择要绑定的指令数据');
        return;
      }
      let obj = Object.assign({}, this.currentRow);
      obj.temId = Math.random() * new Date().valueOf();
      this.$emit('confirm', obj);
      this.switchModalVisible(false);
    }
  },
};
</script>

<style lang="scss">
.dialogContent {
  .el-dialog__body {
    display: flex !important;
    height: 500px;
    overflow: auto;
    padding: 0px 20px;
  }
  .searchForm {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      flex-shrink: 0;
      display: inline-flex;
      flex: 1;
      .el-form-item__label {
        flex-shrink: 0;
      }
      &:last-child {
        flex: 0;
        margin-right: 0px;
      }
    }
    .el-form-item__content {
      position: initial;
    }
    // .searchBtn {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
  }
  .allDirectives {
    position: relative;
    width: 512px;
    flex: none;
    &.allDirectivesLight {
      width: 100%;
    }
    // .allDirectivesTable {
    //   height: 338px;
    //   overflow-y: auto;
    // }
    .el-pagination {
      margin: 24px 0px 0px;
    }
  }
}
</style>

<template>
    <el-dialog
        title="批量设置按钮属性"
        :visible="isShowModal"
        width="500px"
        custom-class="batch-btn-attr-dialog"
        @close="hide"
        :destroy-on-close="true"
        :append-to-body="true"
    >
        <el-form ref="form" :model="formData" label-width="100px">
            <el-form-item v-for="item in allAttrs" :key="item.key" :label="item.label">
                <template v-if="item.type === 'input'">
                    <el-input-number
                        v-if="item.inputType === 'number'"
                        :controls="false"
                        :min="item.min"
                        :precision="item.precision"
                        v-model.number="formData[item.key]"
                    />
                    <!-- textArea文本 -->
                    <el-input
                        v-else-if="item.inputType == 'textarea'"
                        v-model="formData[item.key]"
                        type="textarea"
                    />
                    <el-input v-else v-model="formData[item.key]"></el-input>
                </template>
                 <!-- 下拉选择框 -->
                <el-select
                    v-else-if="item.type === 'select'"
                    v-model="formData[item.key]"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="o in item.options"
                        :key="o.id"
                        :label="o.text"
                        :value="o.id"
                    >
                    </el-option>
                </el-select>
                <!-- 颜色选择 -->
                <el-color-picker
                    v-else-if="item.type === 'colorSet'"
                    v-model="formData[item.key]"
                />
                <UploadArea
                    :src="formData[item.key]"
                    v-else-if="item.type === 'uploadImg'"
                    :handler="
                    () => {
                        showImagesResourceModal(item);
                    }
                    "
                    :deleteHandler="
                    () => {
                        resetImageDefaultSrc(item);
                    }
                    "
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="hide">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        <!-- 图片资源的modal -->
        <ImagesResourceModal
            ref="imagesResourceModal"
            modalOperationKey="other"
            :confirm="setImgConfirm"
        />
    </el-dialog>
</template>
<script>
import { fontFamilyOptions } from '@/utils/attrNameData';
import UploadArea from "@/components/UploadArea";
import ImagesResourceModal from "@/components/ImagesResourceModal";
import { isEmpty } from '@/utils/utils';

export default {
    data() {
        return {
            isShowModal: false,
            allAttrs: [
                { label: "按钮名称", key: "propValue", type: 'input', inputType: 'textarea' },
                { label: "按压名称", key: "btnPressName", type: 'input' },
                { label: "别名", key: "alias", type: 'input' },
                { label: "字体颜色", key: "color", type: 'colorSet' },
                { label: "背景图", key: "backgroundImage", type: 'uploadImg' },
                { label: "背景颜色", key: "backgroundColor", type: 'colorSet' },
                { label: "按压背景图", key: "pressBackgroundImage", type: 'uploadImg' },
                { label: "按压背景颜色", key: "pressBackgroundColor", type: 'colorSet' },
                { label: "字体", key: "fontFamily", type: 'select', options: fontFamilyOptions },
                { label: "字体大小", key: "fontSize", type: 'input', min: 0, precision: 0, inputType: 'number' }
            ],
            formData: this.initForm(),
            selectImgItem: null,
            imgColorMap: {
                backgroundImage: 'backgroundColor',
                pressBackgroundImage: 'pressBackgroundColor'
            },
            retainColorKeys: []
        };
    },
    components: {
        UploadArea,
        ImagesResourceModal
    },
    methods: {
        initForm() {
            return {
                propValue: '',
                btnPressName: '',
                alias: '',
                color: '',
                backgroundImage: '',
                backgroundColor: '',
                pressBackgroundImage: '',
                pressBackgroundColor: '',
                fontFamily: '',
                fontSize: undefined
            }
        },
        show() {
            this.retainColorKeys = [];
            this.formData = this.initForm();
            this.isShowModal = true;
        },
        hide() {
            this.isShowModal = false;
        },
        confirm() {
            let params = {
                style: {
                    ...this.formData
                }
            };
            delete params.style.propValue;
            if (!isEmpty(this.formData.propValue)) {
                params.propValue = this.formData.propValue;
            }
            Object.keys(params.style).map((key) => {
                if (this.retainColorKeys.indexOf(key) !== -1) {
                    params.style[key] = '';
                } else if (this.imgColorMap[key] && params.style[this.imgColorMap[key]]) {
                    params.style[key] = '';
                } else if (isEmpty(params.style[key])) {
                    delete params.style[key];
                }
            });
            if (Object.keys(params.style).length === 0) {
                delete params.style;
            }
            console.log(params);
            this.$emit('confirm', params);
            this.hide();
        },
        showImagesResourceModal(item) {
            this.selectImgItem = item;
            this.$refs.imagesResourceModal.switchDialogVisible(true);
        },
        resetImageDefaultSrc(item) {
            this.formData[item.key] = '';
            let colorKey = this.imgColorMap[this.selectImgItem.key];
            if (colorKey) {
                this.retainColorKeys.splice(this.retainColorKeys.indexOf(colorKey), 1);
            }
        },
        setImgConfirm(src) {
            console.log(src);
            this.formData[this.selectImgItem.key] = `url(${src})`;
            // 由于设置了背景图片时候，应该把对应的背景颜色设置为''，所以需要手动清空下
            let colorKey = this.imgColorMap[this.selectImgItem.key];
            if (colorKey) {
                this.formData[colorKey] = '';
                this.retainColorKeys.push(colorKey);
            }
            this.$refs.imagesResourceModal.switchDialogVisible(false);
        }
    }
}
</script>
<style lang="scss">
.batch-btn-attr-dialog {
    .el-dialog__body {
        max-height: 500px;
        overflow: auto;
    }
    .el-input-number .el-input__inner {
        text-align: left;
    }
}
</style>

<template>
  <el-dialog
    :visible="dialogVisible"
    width="900px"
    custom-class="materialListWrap"
    :append-to-body="true"
  >
    <el-tabs v-model="activeName" v-if="dialogVisible">
      <el-tab-pane label="我的素材" name="my">
        <div class="list">
          <div
            v-for="o in materialListDataInfo.result"
            :class="['one', selectedM.id == o.id && 'selected']"
            :key="o.id"
            @click="selectedM = o"
          >
            <div class="top">
              <img :src="o.file_down_path" class="image" />
            </div>
            <div class="bottom" :title="o.file_real_name">
              {{ o.file_real_name }}
            </div>
            <i class="el-icon-success selectedIcon" v-if="selectedM.id == o.id"></i>
          </div>
          <Pagination
            v-if="materialListDataInfo.total > 7"
            :handleSizeChange="handleSizeChange"
            :handleCurrentPageChange="handleCurrentPageChange"
            :total="materialListDataInfo.total"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="公共素材" name="common">
        <div class="common-material-container">
          <CommonMaterial :selectedM="selectedM" v-if="activeName === 'common'" :isDialog="true" @selected="commonSelected"></CommonMaterial>
        </div>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <el-button @click="quickEntrance">快捷入口</el-button>
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="confirmToChange">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "@/components/Pagination";
import CommonMaterial from './CommonMaterial';
import { mapState, mapActions } from "vuex";
import { getCurComponentsInd } from '@/utils/utils';
export default {
  props: {
    modalOperationKey: String,
    setCarouselPics: Function,
    confirm: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Pagination,
    CommonMaterial
  },
  data() {
    return {
      dialogVisible: false,
      materialName: "",
      selectedM: {},
      materialListDataInfo: {
        result: [],
      },
      activeName: 'my',
      lightIconKeys: ['lightBackgroundImage', 'validateCorrectIcon', 'validateFailIcon', 'pingCorrectIcon', 'pingFailIcon', 'callbackStatusIcon']
    };
  },
  computed: mapState(["curComponent", "componentData"]),
  watch: {
    materialName(v) {
      this.getMaterialsData({ file_name: v });
    },
    activeName(val, oldVal) {
      if (val !== oldVal) {
        this.selectedM = {};
      }
    }
  },
  mounted() {
    this.getMaterialsData({});
  },

  methods: {
    ...mapActions([
      'getMaterialTypeList'
    ]),
    commonSelected(item) {
      this.selectedM = item;
    },
    async getPicType() {
        try {
            this.$changeLoadingState(true);
            await this.getMaterialTypeList();
        } catch (error) {
            console.log(error);
        }
        this.$changeLoadingState(false);
    },
    handleCurrentPageChange(v) {
      this.getMaterialsData({ page_num: v });
    },
    handleSizeChange(v) {
      this.getMaterialsData({ page_size: v });
    },
    onClose() {
      this.dialogVisible = false;
      this.selectedM = {};
    },
    getMaterialsData({ page_size = 8, page_num = 1, file_name }) {
      this.$request
        .queryMaterialsList({ page_size, page_num, file_name })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.materialListDataInfo = res.data || {};
        });
    },
    quickEntrance() {
      const routeToOpen = this.$router.resolve('/privateResource/materials').href;
      window.open(routeToOpen, '_blank');
    },
    confirmToChange() {
      if (!this.selectedM.file_down_path) {
        this.$message.warning("请选择图片");
        return;
      }
      //如果画布外其他选图片
      if (this.modalOperationKey == "other") {
        this.confirm(this.selectedM.file_down_path);
        return;
      }
      const all = this.componentData;
      // const index = this.componentData.findIndex(
      //   (i) => i.id == this.curComponent.id
      // );
      const ret = getCurComponentsInd(this.componentData, this.curComponent);
      let obj = {};
      const { file_down_path, file_real_name, file_md5, id, file_name } =
        this.selectedM;
      if (this.modalOperationKey == "imageResource") {
        obj = {
          ...this.curComponent,
          propValue: {
            file_down_path,
            file_real_name,
            file_md5,
            id,
            file_name,
          },
        };
      } else if (this.modalOperationKey == "carouselPics") {
        this.setCarouselPics(this.selectedM);
        this.dialogVisible = false;
        this.selectedM = {};
        return;
      } else if (this.lightIconKeys.indexOf(this.modalOperationKey) !== -1) {
        // 状态灯相关的图标选择
        this.$emit('confirm', this.selectedM);
        this.dialogVisible = false;
        this.selectedM = {};
        return;
      } else {
        obj = {
          ...this.curComponent,
          style: {
            ...this.curComponent.style,
            [this.modalOperationKey == "backgroundImage" ||
            this.modalOperationKey == "backPicSrc"
              ? "backgroundInfo"
              : "pressBackgroundImageInfo"]: {
              file_down_path,
              file_real_name,
              file_md5,
              id,
              file_name,
            },
            ...(this.modalOperationKey == "backgroundImage"
              ? { backgroundColor: "" }
              : {}),
            [this.modalOperationKey]: `url(${file_down_path})`,
          },
        };
      }
      // all.splice(index, 1, obj);
      if (ret.parentInd !== -1) {
        // 生态组件下的子组件
        all[ret.parentInd].propValue.splice(ret.index, 1, obj);
      } else {
        all.splice(ret.index, 1, obj);
      }
      this.$store.commit("setComponentData", all);
      this.$store.commit("setCurComponent", {
        component: {
          ...this.curComponent,
          ...obj,
        },
      });
      this.dialogVisible = false;
      this.selectedM = {};
    },
    switchDialogVisible(bol) {
      this.dialogVisible = bol;
      if (bol) {
        this.getPicType();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
.common-material-container {
    height: 300px;
  .el-pagination {
    margin-bottom: 0px;
  }
}
.materialListWrap {
  .el-dialog__header {
    // border: 1px solid #e6e6e6;
    display: none;
  }
  .el-dialog__body {
    padding: 20px !important;
  }
  .search {
    width: 200px;
    margin: 0 0 10px 10px;
  }
  .list {
    .el-pagination {
      margin-bottom: 0px;
    }
    .one {
      margin: 6px;
      background-color: #fff;
      width: 200px;
      height: 150px;
      cursor: pointer;
      border-radius: 6px;
      display: inline-block;
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
      border: 3px solid #f5f5f5;
      box-sizing: border-box;
      position: relative;
      .image {
        width: 100%;
        height: 114px;
      }
      .bottom {
        font-size: 16px;
        color: #607895;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 3px 8px;
        background-color: #ececec;
        width: 193px;
      }
    }
    .selected {
      border: 3px solid #fa9d07;
    }
    .el-icon-success {
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fa9d07;
      opacity: 1;
      width: 22px;
      align-items: center;
      justify-content: center;
      height: 22px;
      display: flex;
      font-size: 28px;
      background-color: #fff;
    }
  }
}
</style>
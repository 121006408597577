<template>
  <div class="eventsConfigWrap">
    <div class="div-events">
      <div class="label">绑定事件：</div>
      <div class="eventItem" v-for="e in buttonEventsConfig" :key="e.key">
        <el-button type="primary" @click="btnClickedHandler(e)">{{
          e.text
        }}</el-button>
      </div>
    </div>

    <BindEventsModal :activeEventType="activeEventType" ref="bindEventsModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BindEventsModal from "./BindEventsModal.vue";

export default {
  components: { BindEventsModal },
  data() {
    return {
      ipAdress: "",
      modalType: 0,
      buttonEventsConfig: [],
      activeEventType: {
        text: "互斥事件",
        key: "mutuallyEvent",
      },
    };
  },
  computed: {
    ...mapState(["curComponent"]),
  },
  mounted() {
    this.initEventsAttrs();
  },
  watch: {
    curComponent: {
      handler(v) {
        this.initEventsAttrs(v);
      },
      deep: true,
    },
  },
  methods: {
    btnClickedHandler(e) {
      this.activeEventType = e;
      this.$refs.bindEventsModal.switchModalVisible(true);
    },
    initEventsAttrs(v) {
      let items = [
        {
          text: "互斥事件",
          key: "mutuallyEvent",
        },
        {
          text: "跳转事件",
          key: "jumpEvent",
        },
        {
          text: "联动事件",
          key: "pressEvent",
        },
        {
          text: "再次按压联动事件",
          key: "pressAgainEvent",
        },
      ];
      const { component, style } = v || this.curComponent;
      if (component == "v-button") {
        if (!style.oneClick) {
          items = items.filter((item) => item.key != "jumpEvent");
        }
        if (style.oneClick) {
          items = items.filter((item) => item.key != "pressAgainEvent");
        }
      }
      this.buttonEventsConfig = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.eventsConfigWrap {
  .eventItem {
    display: flex;
    align-items: center;
    margin: 16px 24px;
  }
}
.modalContent {
  height: 100%;
  width: 100%;
  padding: 24px;
}
</style>

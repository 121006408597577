<template>
  <div class="directivesWrap">
    <div class="propertiesWrap">
      <div class="tip" v-if="curComponent.component == 'MatrixExecute'">
        <div>X代表输入按键中的输入位</div>
        <div>Y代表输出按键中的输出位</div>
      </div>
      <div
        :class="[
          'propertyItem',
          twoLinesInfoKeys.includes(n.key) && 'twoLinesInfo',
        ]"
        v-for="n in networkConfigItems"
        :key="n.key"
      >
        <div class="label">{{ n.label }}</div>
        <template v-if="numberInputKeys.includes(n.key)">
          <el-input-number
            v-model="curComponent.networkConfig[n.key]"
            :placeholder="`请输入${n.label}`"
            @keydown.native.stop
            :controls="false"
            :min="limitVal(n.key, 'min')"
            :precision="limitVal(n.key, 'precision')"
            @change="inputValChange(n.key)"
          />
          <span class="attr-error-tip">{{ errorMsg(n.key) || '' }}</span>
        </template>
        <template v-else-if="inputFormKeys.includes(n.key)">
          <el-input
            v-model="curComponent.networkConfig[n.key]"
            :placeholder="`请输入${n.label}`"
            @keydown.native.stop
            @change="inputValChange(n.key)"
          />
          <span class="attr-error-tip">{{ errorMsg(n.key) || '' }}</span>
        </template>

        <!-- 滑块单元设置组件 -->
        <div class="sliderListGroups" v-if="n.key == 'sliderList'">
          <el-button type="primary" class="addGroup" @click="addSliderListGroup"
            >添加</el-button
          >
          <span class="attr-error-tip" style="top: 25px; left: 0px">{{
            errorMsg(n.key) || ''
          }}</span>
          <div
            class="sliderListGroup"
            v-for="g in curComponent.networkConfig[n.key]"
            :key="g.id"
          >
            <el-input
              v-model="g.unit"
              placeholder="单位"
              @change="inputValChange(n.key)"
            ></el-input>
            <el-input
              v-model="g.code"
              placeholder="代码"
              @change="inputValChange(n.key)"
            ></el-input>
            <i
              class="el-icon-delete deleteGroup"
              @click="deleteGroup(g.id)"
            ></i>
          </div>
        </div>

        <div @click="showModal(1)" v-if="n.key == 'directives'">
          <el-button type="primary">添加指令</el-button>
          <div class="directives">
            <el-tag
              type="info"
              v-for="d in curComponent.networkConfig.directives"
              :key="d.order"
              closable
              @close="deleteDirective(d, 'Directives')"
              >{{ d.instruction_name }}</el-tag
            >
          </div>
        </div>

        <div @click="showModal(2)" v-if="n.key == 'directives2'">
          <el-button type="primary">添加二次按钮指令</el-button>
          <div class="directives">
            <el-tag
              type="info"
              v-for="d in curComponent.networkConfig.directives2"
              :key="d.id"
              closable
              @close="deleteDirective(d, 'Directives2')"
              >{{ d.instruction_name }}</el-tag
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 选择指令 -->
    <DirectivesModal :modalType="modalType" ref="directivesModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DirectivesModal from './DirectivesModal.vue'
import {
  COMPONENT_NETWORDK_CONFIG_ATTRS,
  ATTRS_VALIDATE_MAP,
} from '@/utils/attrNameData'
import eventBus from '@/utils/eventBus'
export default {
  components: { DirectivesModal },
  data() {
    return {
      ipAdress: '',
      modalType: 0,
      networkConfigItems: [],
      inputFormKeys: ['ipAdress', 'rulesEngine'],
      numberInputKeys: ['port', 'input', 'output', 'count'],
      twoLinesInfoKeys: ['sliderList'],
    }
  },
  computed: {
    ...mapState(['curComponent', 'curErrorMap', 'selectedCurrentPage']),
    limitVal() {
      return (key, propKey) => {
        const val = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key][propKey]
        if (val !== null && val !== undefined && val !== '') {
          return val
        }
        return undefined
      }
    },
    errorMsg() {
      return (key) => {
        const id = this.curComponent.id
        return this.curErrorMap[id] && this.curErrorMap[id][key]
      }
    },
  },
  watch: {
    curComponent: {
      handler(v) {
        this.initNetworkConfigItems(v)
      },
      deep: true,
    },
  },
  mounted() {
    this.initNetworkConfigItems()
  },
  methods: {
    addSliderListGroup() {
      this.$store.commit('changeSliderListGroups', {
        unit: undefined,
        code: '',
        id: new Date().valueOf(),
      })
    },
    deleteGroup(id) {
      this.$store.commit('deleteSliderListGroup', id)
    },
    inputValChange(key) {
      const validateFunc =
        ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate
      if (validateFunc && typeof validateFunc === 'function') {
        eventBus.$emit('inputValidate', {
          key,
          val: this.curComponent.networkConfig[key],
          id: this.curComponent.id,
          otherVal: this.curComponent.networkConfig,
        })
      }
    },
    initNetworkConfigItems(v) {
      const { component, style } = v || this.curComponent
      let items =
        COMPONENT_NETWORDK_CONFIG_ATTRS[(v || this.curComponent).component]
      if (component == 'v-button' && style.oneClick) {
        items = items.filter((i) => i.key != 'directives2')
      }
      this.networkConfigItems = items
    },
    showModal(modalType) {
      //modalType : 1  按压指令 2 二次按压指令
      this.modalType = modalType
      setTimeout(() => {
        this.$refs.directivesModal.switchModalVisible(true)
      }, 40)
    },
    deleteDirective(d, property) {
      const tags = this.curComponent.networkConfig[property.toLowerCase()]
      const ret = tags.filter((item) => item.temId != d.temId)
      this.$store.commit(`bind${property}`, ret)
    },
  },
}
</script>

<style lang="scss">
.propertyItem {
  .el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
<style lang="scss" scoped>
.twoLinesInfo {
  flex-direction: column;
  align-items: flex-start !important;
}
.sliderListGroups {
  .addGroup {
    margin: 4px 0 10px;
  }
  .sliderListGroup {
    border: 1px solid #dadada;
    position: relative;
    padding: 6px;
    line-height: 40px;
    margin-bottom: 10px;
    margin-right: 20px;
    .deleteGroup {
      position: absolute;
      // top: 38px;
      right: -32px;
      font-size: 22px;
      cursor: pointer;
      color: #f56c6c;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
}
.directivesWrap {
  .propertyItem {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin: 16px 0px;
    .label {
      font-size: 12px;
      color: #606266;
      margin: 10px 0;
      min-width: 56px;
    }
  }

  .propertiesWrap {
    padding: 0 20px;
    .tip {
      font-size: 12px;
      line-height: 18px;
    }
    .el-button {
      display: inline-block;
      margin-bottom: 10px;
    }

    .label {
      // line-height: 32px;
      cursor: pointer;
    }
    .directives {
      display: flex;
      flex-wrap: wrap;
      .el-tag {
        margin: 0 8px 8px 0 !important;
      }
    }
  }
}
</style>

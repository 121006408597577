<template>
  <el-dialog
    :visible="dialogVisible"
    width="900px"
    custom-class="carouselPicModal"
    :show-close="false"
  >
    <div class="contentWrap">
      <div class="list" v-if="picturesData.length">
        <el-checkbox v-model="isAllChecked" @change="allCheckedChange">
          全选
          <span v-if="checkedPictures.length" class="selectedNum">
            已选中{{ checkedPictures.length }}个文件
          </span>
        </el-checkbox>
        <div
          :class="['onePicture', isActive(p.id) && 'activePicture']"
          v-for="(p, index) in picturesData"
          @click="clickHandler(p)"
          :key="p.id"
        >
          <img :src="p.file_down_path" class="image" />
          <div>{{ p.name }}</div>
          <!-- <div class="tag">{{ p.size || "148.56KB" }}</div>
          <div class="tag">{{ p.size || "1780X828" }}</div> -->
          <div :class="['pictureNum', isActive(p.id) && 'activeNum']">
            {{ index }}
          </div>
        </div>
      </div>
      <div class="emptyList" v-else>暂无数据</div>
      <div class="toolsBar">
        <div>工具栏</div>
        <div
          :class="[
            'tool',
            t.key == 'delete' && 'deleteTool',
            isdisabled(t.key) && 'disabledTool',
          ]"
          v-for="t in tools"
          :key="t.key"
          @click="toolClickHandler(t.key)"
        >
          <i :class="['iconfont', `icon-${t.icon}`]"></i>
        </div>
      </div>
    </div>
    <span slot="title" class="modalHeader">
      <span>内容列表</span>
      <span class="operations">
        <el-button type="primary" @click="confirmToChange($event)">
          <i class="iconfont icon-baocun"></i>
          保存
        </el-button>
        <el-button type="primary" @click="dialogVisible = false">
          关闭
        </el-button>
      </span>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      dialogVisible: false,
      isAllChecked: false,
      checkedPictures: [],
      picturesData: [],
      tools: [
        {
          key: "add",
          icon: "tianjia",
        },
        {
          key: "up",
          icon: "shangyi",
        },
        {
          key: "down",
          icon: "xiayi",
        },
        {
          key: "delete",
          icon: "shanchu",
        },
      ],
    };
  },
  props: {
    showImagesResourceModal: Function,
  },
  computed: mapState(["curComponent", "componentData"]),
  methods: {
    isdisabled(key) {
      if (key == "add") {
        return false;
      }
      if (!this.checkedPictures.length) return true;
    },
    allCheckedChange(v) {
      this.checkedPictures = v ? this.picturesData : [];
    },
    toolClickHandler(key) {
      if (key == "add") {
        this.showImagesResourceModal("carouselPics");
      } else if (key == "delete") {
        this.picturesData = _.remove(this.picturesData, (n) => {
          return _.findIndex(this.checkedPictures, (o) => o.id == n.id) == -1;
        });
        // for (let i = (this.picturesData.length - 1);i>=0;i++) {
        //   let item = this.picturesData[i];
        //   let index = _.findIndex(this.checkedPictures, (o) => o.id == item.id);
        //   if (index !== -1) {
        //     this.picturesData.splice(index, 1);
        //   }
        // }
      } else if (key == "up" || key == "down") {
        //移动思想：先删除选择的那几个，然后将选择那几个塞到指定位置
        let arr = _.cloneDeep(this.picturesData);
        const checkedIndexArr = this.checkedPictures
          .reduce((res, item) => {
            res.push(arr.findIndex((i) => i.id == item.id));
            return res;
          }, [])
          .sort();
        arr = _.remove(arr, (n) => {
          return _.findIndex(this.checkedPictures, (o) => o.id == n.id) == -1;
        });

        let position = checkedIndexArr[0];
        if (key == "up") {
          //防止上移的位置为负数
          position = checkedIndexArr[0] - 1 >= 0 ? checkedIndexArr[0] - 1 : 0;
        } else {
          //防止要下移的位置超过最底部
          if (checkedIndexArr[0] + 1 >= this.picturesData.length) {
            return;
          }
          position = checkedIndexArr[0] + 1;
        }
        arr.splice(position, 0, ...this.checkedPictures);
        this.picturesData = arr;
      }
    },
    setCarouselPics(v) {
      this.picturesData = _.uniqBy([...this.picturesData, v], "id");
    },
    initCheckedPictures() {
      const { carouselPics } = this.curComponent.style;
      this.picturesData = (JSON.parse(JSON.stringify(carouselPics || [])));
    },
    clickHandler(p) {
      const ind = _.findIndex(this.checkedPictures, { id: p.id });
      if (ind > -1) {
        //取消选中
        this.checkedPictures.splice(ind, 1);
        return;
      }
      this.checkedPictures = _.uniqBy([p, ...this.checkedPictures], "id");
      if (this.checkedPictures.length == this.picturesData.length) {
        this.isAllChecked = true;
      } else if (this.checkedPictures.length == 0) {
        this.isAllChecked = false;
      }
    },
    isActive(id) {
      return this.checkedPictures.findIndex((i) => i.id == id) > -1;
    },
    confirmToChange(e, isClear) {
      const all = this.componentData;
      const index = this.componentData.findIndex(
        (i) => i.id == this.curComponent.id
      );
      const pictures = (JSON.parse(JSON.stringify(this.picturesData))).reduce(
        (arr, { id, file_down_path, file_real_name, file_md5, file_name }) => {
          arr.push({
            id,
            file_down_path,
            file_real_name,
            file_md5,
            file_name,
          });
          return arr;
        }, []);
      let obj = {
        ...this.curComponent,
        style: {
          ...this.curComponent.style,
          carouselPics: isClear ? [] : pictures,
        },
      };
      all.splice(index, 1, obj);
      this.$store.commit("setComponentData", all);
      this.$store.commit("setCurComponent", {
        component: {
          ...this.curComponent,
          ...obj,
        },
      });
      this.dialogVisible = false;
    },
    switchDialogVisible(bol) {
      this.dialogVisible = bol;
    },
  },
};
</script>

<style lang="scss">
.carouselPicModal {
  .modalHeader {
    display: flex;
    justify-content: space-between;
  }
  .contentWrap {
    display: flex;
    height: 500px;
    overflow: auto;
    .emptyList {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
    .list {
      flex: 1;
      padding: 20px;
      height: 100%;
      .selectedNum {
        color: #569ef8;
      }
      .activePicture {
        background-color: rgba(86, 158, 248, 0.05) !important;
        border: 1px solid rgba(86, 158, 248, 0.8) !important;
      }
      .el-checkbox__inner {
        height: 22px;
        width: 22px;
      }
      .el-checkbox__inner::after {
        height: 13px;
        left: 7px;
        top: 1px;
        width: 7px;
      }
      .el-checkbox__label {
        font-size: 18px;
      }
      .el-checkbox {
        margin-bottom: 12px;
      }
      .onePicture {
        height: 80px;
        cursor: pointer;
        border: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        border-radius: 4px;
        margin-top: 12px;
        position: relative;

        .image {
          width: 160px;
          height: 80px;
          margin-right: 12px;
        }
        .pictureNum {
          text-align: center;
          font-weight: 700;
          padding-right: 2px;
          width: 30px;
          height: 20px;
          background-color: #f0f2f5;
          border-bottom-right-radius: 10px;
          line-height: 20px;
          position: absolute;
          top: -1px;
        }
        .activeNum {
          background-color: #569ef8 !important;
          color: #fff;
        }

        .tag {
          height: 2.3em;
          line-height: 2.3em;
          padding: 0 8px;
          background-color: #ecf5ff;
          font-size: 12px;
          color: #409eff;
          border: 1px solid #d9ecff;
          border-radius: 4px;
          margin-left: 10px;
        }
      }
    }
    .toolsBar {
      width: 56px;
      padding: 16px 16px 0;
      box-sizing: content-box;
      border-left: 1px solid #dbdcdd;
      text-align: center;
      font-size: 18px;
      .deleteTool {
        background-color: rgba(245, 108, 108, 0.101961) !important;
        color: #f56c6c !important;
      }
      .disabledTool {
        cursor: not-allowed !important;
        background-color: #fff !important;
      }
      .tool {
        font-size: 19px;
        width: 56px;
        height: 50px;
        padding: 0;
        margin-top: 22px;
        margin-left: 0;
        background-color: #f0f2f5;
        border: 1px solid #dbdcdd;
        color: #909398;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
          font-size: 22px !important;
        }
      }
    }
  }
  .el-dialog__header {
    background-color: #eee !important;
    padding: 20px 16px;
    text-align: left;
    border-bottom: 1px solid #dbdcdd;
    font-size: 22px;
    font-weight: 700;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .operations {
    .el-button--primary {
      height: 60px;
      font-size: 16px;
    }
  }
}
</style>
<template>
  <div class="components" :style="{ maxHeight: maxH ? maxH + 'px' : 'auto' }">
    <el-tree
      ref="compTree"
      :data="treeDatas"
      node-key="id"
      default-expand-all
      :highlight-current="true"
      :expand-on-click-node="false"
      current-node-key="Back"
      @current-change="nodeChange"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      @node-drag-end="handleDragEnd"
    >
      <span class="comp-tree-node" :style="{ background: isError(data.id) ? '#fda6a6' : 'inherit'}" slot-scope="{ node, data }">
        <div class="comp-left-label">
          <i v-if="componentIconConfig[data.component]" :class="['iconfont', componentIconConfig[data.component].indexOf('el') !== -1 ? componentIconConfig[data.component] : `icon-${componentIconConfig[data.component]}`]" />
          <span
            :title="data.label"
          >{{ data.component === "v-button" ? data.propValue : data.label }}</span>
          <!-- <span
            :title="data.label"
            :style="{ color: isError(data.id) ? 'red': 'inherit' }"
          >{{ data.component === "v-button" ? data.propValue : data.label }}</span> -->
          <template v-if="data.id != 'Back'">
            <el-tag v-if="data.compType === ECOLOGICAL_SIGN || node.level > 1" size="mini" type="danger" effect="dark">生态控件</el-tag>
            <el-tag v-else size="mini" type="success" effect="dark">基本控件</el-tag>
          </template>
        </div>
        <span v-if="data.id != 'Back'" class="tree-right-btn">
          <el-button
            type="text"
            size="mini"
            v-if="data.isLock"
            @click.stop="unlock(data)">
            <i class="el-icon-lock" />
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-else
            @click.stop="lock(data)">
            <i class="el-icon-unlock" />
          </el-button>
          <el-popconfirm
            :title="data.compType === ECOLOGICAL_SIGN ? '删除当前按钮会清空组内所有按钮，确定要删除吗？' : '确定要删除吗？'"
            @confirm="deleteC(data)"
          >
            <el-button
              slot="reference"
              type="text"
              size="mini"
            >
              <i class="el-icon-delete" />
            </el-button>
          </el-popconfirm>
        </span>
      </span>
    </el-tree>

    <!-- <div
      v-for="c in componentData"
      :key="c.id"
      :class="['component', activeCId == c.id && 'activeC']"
      @click="setActive(c)"
    >
      <div class="type" :style="{background: isError(c.id) ? 'red': '#d2d2d2' }">
        <i :class="['iconfont', `icon-${componentIconConfig[c.component]}`]" />
        <div class="text">{{ c.label }}</div>
      </div>
      <div class="operations">
        <span v-if="c.id != 'Back'">
          <i class="el-icon-delete" @click.stop="deleteC(c)" />
          <i class="el-icon-lock" v-if="c.isLock" @click.stop="unlock" />
          <i class="el-icon-unlock" v-else @click.stop="lock" />
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ECOLOGICAL_SIGN, ECOLOGICAL_CHILDREN_SIGN } from '@/constants/editor';

import { componentIconConfig } from "@/custom-component/component-list.js";
export default {
  props: ['maxH'],
  data() {
    return {
      componentIconConfig,
      activeCId: "",
      ECOLOGICAL_SIGN,
      ECOLOGICAL_CHILDREN_SIGN
    };
  },
  computed: {
    ...mapState(["componentData", "curComponent", "curErrorMap", "selectedCurrentPage"]),
    isError() {
      return (id) => {
        const info = this.curErrorMap[id];
        if (info) {
          let hasError = false;
          for (let key in info) {
            if (typeof(info[key]) === 'string' && info[key]) {
              hasError = true;
              break;
            } else if (typeof(info[key]) === 'object') {
              // 状态灯的-被动监听，多个设备时，需要再次嵌套
              for (let deviceAttrId in info[key]) {
                if (info[key][deviceAttrId]) {
                  hasError = true;
                  break;
                }
              }
            }
          }
          return hasError;
        }
        return false;
      };
    },
    treeDatas() {
      let datas = [];
        if (this.componentData && this.componentData.length !== 0) {
          this.componentData.map((item) => {
            if (item.compType === ECOLOGICAL_SIGN) {
              let ecologyItem = Object.assign({}, item, {
                children: []
              });
              item.propValue && item.propValue.map((childItem) => {
                ecologyItem.children.push(childItem);
              });
              datas.push(ecologyItem);
            } else {
              datas.push(item);
            }
          });
        }
        return datas;
    }
  },
  mounted() {
    //第一个页面加载时,初始化第一个选中组件
    this.setActive(this.componentData[0], 0);
  },
  watch: {
    curComponent(c) {
      this.activeCId = c?.id;
      this.$nextTick(() => {
        this.$refs.compTree.setCurrentKey(c?.id);
      });
    },
    //在切换页面后,默认选中第一个组件
    selectedCurrentPage(key) {
      this.setActive(this.componentData[0], 0);
    },
  },
  methods: {
    allowDrop(draggingNode, dropNode, type) {
      return type !== 'inner' && dropNode.data.id !== 'Back';
    },
    allowDrag(draggingNode) {
      return draggingNode.data.id !== 'Back' && draggingNode.level === 1;
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', draggingNode, dropNode, dropType);
      // 更新两个节点对应的层级
      // let beforeInd = draggingNode.data.style.zIndex;
      // let afterInd = dropNode.data.style.zIndex;
      // draggingNode.data.style.zIndex = afterInd;
      // dropNode.data.style.zIndex = beforeInd;
      // 两个元素交互数组位置
      const dragDodeInd = this.componentData.findIndex(
        (i) => i.id == draggingNode.data.id
      );
      const dropDodeInd = this.componentData.findIndex(
        (i) => i.id == dropNode.data.id
      );
      let data = JSON.parse(JSON.stringify(this.componentData));
      let dragData = JSON.parse(JSON.stringify(data[dragDodeInd]));
      data.splice(dropDodeInd, 0, dragData);
      data.splice(dropDodeInd > dragDodeInd ? dragDodeInd : dragDodeInd + 1, 1);
      // console.log(data, this.componentData);
      // 再更新层级值
      data.map((item, ind) => {
        if (item.style.zIndex !== ind + 1) {
          item.style.zIndex = ind + 1;
        }
      });
      this.$store.commit("setComponentData", data);
    },
    nodeChange(data) {
      const dataInd = this.componentData.findIndex(
        (i) => i.id == data.id
      );
      this.setActive(dataInd !== -1 ? this.componentData[dataInd] : data, dataInd !== -1 ? dataInd : undefined);
    },
    setActive(c, ind) {
      //设置选中的组件
      this.$store.commit("setCurComponent", { component: c, index: ind });
      this.activeCId = c?.id;
      this.$nextTick(() => {
        this.$refs.compTree.setCurrentKey(c?.id);
      });
    },
    unlock(data) {
      if (this.curComponent.id !== data.id) {
        const dataInd = this.componentData.findIndex(
          (i) => i.id == data.id
        );
        if (dataInd !== -1) {
          this.setActive(this.componentData[dataInd], dataInd);
        } else {
          this.$message.error('请先点击选择要解锁的组件');
          return;
        }
      }
      if (!this.curComponent || this.curComponent.id === 'Back') {
        this.$message.error('请先点击选择要解锁的组件');
        return;
      }
      if (this.curComponent.compType === ECOLOGICAL_CHILDREN_SIGN) {
        // 如果父组件锁定了，则子组件不能解锁
        const parentCompInd = this.componentData.findIndex((i) => i.id === this.curComponent.parentid); 
        if (this.componentData[parentCompInd].isLock) {
          this.$message.error('请先解锁父组件，才能解锁当前组件');
          return;
        }
      }
      this.$store.commit("unlock");
      if (this.curComponent.compType === ECOLOGICAL_SIGN) {
        // 生态组件要将子组件下的一并处理了
        this.curComponent.propValue.map((item, index) => {
          this.$store.commit("setEcologicalChildData", {
            index: index,
            key: 'isLock',
            value: false
          });
        });
      }
    },
    lock(data) {
      if (this.curComponent.id !== data.id) {
        const dataInd = this.componentData.findIndex(
          (i) => i.id == data.id
        );
        if (dataInd !== -1) {
          this.setActive(this.componentData[dataInd], dataInd);
        } else {
          this.$message.error('请先点击选择要锁定组件');
          return;
        }
      }
      if (!this.curComponent || this.curComponent.id === 'Back') {
        this.$message.error('请先点击选择要锁定组件');
        return;
      }
      this.$store.commit("lock");
      if (this.curComponent.compType === ECOLOGICAL_SIGN) {
        // 生态组件要将子组件下的一并处理了
        this.curComponent.propValue.map((item, index) => {
          this.$store.commit("setEcologicalChildData", {
            index: index,
            key: 'isLock',
            value: true
          });
        });
      }
    },
    deleteC(c) {
      this.setActive(c);
      setTimeout(() => {
        this.$store.commit("deleteComponent");
        this.$store.commit("recordSnapshot");
      }, 50);
    },
  },
};
</script>

<style lang="scss">
.components {
  .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background: #bdd9f9;
  }
  .el-tree-node__content {
    height: 40px;
  }
  .comp-tree-node {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
    .comp-left-label {
      flex: 1 0 auto;
      max-width: calc(100% - 36px);
      overflow: hidden;
      display: flex;
      align-items: center;
      i {
        line-height: 22px;
      }
      > span:first-of-type {
        max-width: calc(100% - 64px);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      .el-tag {
        margin-left: 3px;
      }
    }
    .tree-right-btn {
      width: 36px;
      flex-shrink: 0;
      margin-right: 10px;
      .el-button {
        font-size: 16px;
        margin: 0px;
        float: right;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.components {
  height: calc(100% - 132px);
  overflow: auto;
  .component {
    height: 60px;
    background-color: rgb(243, 243, 243);
    display: flex;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    width: 230px;
    display: flex;
    margin-bottom: 16px;
    .type {
      flex: 1;
      background-color: rgb(210, 210, 210);
      position: relative;
      .iconfont {
        position: absolute;
        left: 28%;
        top: 20%;
        font-size: 30px;
        color: #fff;
      }
      .img {
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        z-index: 1;
        right: 8px;
        bottom: 8px;
        font-size: 12px;
        line-height: 1em;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.6);
        padding: 4px 6px;
        border-radius: 4px;
      }
    }
    .operations {
      flex: 1;
      display: flex;
      align-items: flex-end;
      font-size: 22px;
      padding: 10px 0;
      & span {
        margin-left: 10px;
        color: rgb(144, 147, 153);
      }
      & i {
        margin-left: 10px;
      }
      .el-icon-delete {
        color: #f56c6c;
      }
    }
  }
  .activeC {
    border: 1px solid #1c92ff;
  }
}
</style>